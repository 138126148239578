export enum TransactionResultEnum {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
  PENDING = 'PENDING'
}

export type TransactionResultType =
  | TransactionResultEnum.ACCEPTED
  | TransactionResultEnum.CANCELLED
  | TransactionResultEnum.DENIED
  | TransactionResultEnum.PENDING;

export const TransactionResultArray = [
  TransactionResultEnum.ACCEPTED,
  TransactionResultEnum.CANCELLED,
  TransactionResultEnum.DENIED,
  TransactionResultEnum.PENDING
];

export const TransactionResultInfo = [
  {
    [TransactionResultEnum.ACCEPTED]: {
      name: 'Aceptada',
    },
  },
  {
    [TransactionResultEnum.CANCELLED]: {
      name: 'Cancelada',
    },
  },
  {
    [TransactionResultEnum.DENIED]: {
      name: 'Denegada',
    },
  },
  {
    [TransactionResultEnum.PENDING]: {
      name: 'Pendiente',
    },
  },
];
