<div class="row justify-content-center">
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <mat-card @enterFadeIn class="bg-full-card h-100 py-4">
      <mat-card-content class="h-100">
        <div class="d-flex flex-column h-100">
          <div class="mat-h3 px-4 flex-grow-1">{{'Ventas totales'|translate}}</div>
          <div class="container-amount text-center" [ngClass]="{ invisible: !(totalAmounts$|async) }">
            {{(totalAmounts$|async)?.fullAmountTotals||0|integerToCurrency|currency}}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <mat-card @enterFadeIn class="bg-sale-card h-100 py-4">
      <mat-card-content class="h-100">
        <div class="d-flex flex-column h-100">
          <div class="mat-h3 px-4 flex-grow-1">{{'Descuentos realizados'|translate}}</div>
          <div class="container-amount text-center" [ngClass]="{ invisible: !(totalAmounts$|async) }">
            {{(totalAmounts$|async)?.saleTotals||0|integerToCurrency|currency}}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <mat-card @enterFadeIn class="bg-add-card h-100 py-4">
      <mat-card-content class="h-100">
        <div class="d-flex flex-column h-100">
          <div class="mat-h3 px-4 flex-grow-1">{{'Liquidado al establecimiento'|translate}}</div>
          <div class="container-amount text-center" [ngClass]="{ invisible: !(totalAmounts$|async) }">
            {{(totalAmounts$|async)?.addTotals||0|integerToCurrency|currency}}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
