<!-- <p @toggleFade class="lead">{{ "¿Tienes alguna duda?" | translate }}</p>
<a
  @toggleFade
  [href]="AppUrlsConfig.bonoCarrusel"
  class="qaroni-link"
  target="_blank"
  download
>
  <div class="d-inline-flex flex-row align-items-center">
    <mat-icon class="mr-1">help_outline</mat-icon>
    <span class="text-truncate">{{
      "Descarga el instructivo" | translate
    }}</span>
  </div>
</a> -->

<div class="mat-headline">{{ "¿Necesitas ayuda adicional?" | translate }}</div>
<p class="lead">
  {{ "Echa un vistazo a" | translate }}
  <a class="qaroni-link" [routerLink]="['/help-video']">{{
    "este vídeo" | translate
  }}</a
  >, {{ "quizás puede resolver tus dudas" | translate }}.
</p>
