import { Component, OnInit } from '@angular/core';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';

@Component({
  selector: 'qaroni-alert-user-active',
  templateUrl: './alert-user-active.component.html',
  styleUrls: ['./alert-user-active.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class AlertUserActiveComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
