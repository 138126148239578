<div class="d-flex flex-wrap mat-small" *ngIf="address">
  <span class="font-weight-bold mr-1" *ngIf="address?.line1"><strong>{{address?.line1}}</strong></span>
  <span class="font-italic mr-1" *ngIf="address?.postalCode">{{address?.postalCode}}</span>
  <span class="font-weight-light mr-1" *ngIf="address?.city">{{address?.city}}</span>
  <span class="font-weight-light" *ngIf="address?.council">{{address?.council}}</span>
  <a [href]="googleMapsUrl" class="qaroni-link" target="_blank" *ngIf="showMapIcon"
    [matTooltip]="'Ver en Google Maps'|translate">
    <mat-icon class="mat-icon-18 va-middle mx-1">map</mat-icon>
  </a>
</div>
