import { Component, Input, OnInit } from '@angular/core';
import { Branch, Merchant } from '@qaroni-core/entities';

@Component({
  selector: 'qaroni-address-string',
  templateUrl: './address-string.component.html',
  styleUrls: ['./address-string.component.scss'],
})
export class AddressStringComponent implements OnInit {
  @Input() address: Branch | Merchant;

  @Input() map = true;

  constructor() {}

  ngOnInit(): void {}

  get showMapIcon(): boolean {
    return this.map && this.address?.latitude && this.address?.longitude
      ? true
      : false;
  }

  get googleMapsUrl(): string {
    if (this.showMapIcon) {
      return `https://www.google.com/maps/search/?api=1&query=${this.address?.latitude},${this.address?.longitude}`;
    }
  }
}
