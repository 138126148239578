import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '../../../core/config/snackbars/snackbar.config';

export const OnlineShoppingSnackbars = {
  failureGetMerchant: {
    message: `Se ha producido un error al obtener la información del establecimiento.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureMerchantIdIsRequired: {
    message: `El Id del establecimiento es requerido.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureFullAmountIsRequired: {
    message: `El importe total es requerido.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureOrderIsRequired: {
    message: `El número de pedido es requerido.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureGetTpvInfoFullAmountIsRequired: {
    message: `El importe total es requerido.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureGetTpvInfoOrderIsRequired: {
    message: `El número de pedido es requerido.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureMerchantHasDisabledOnlineShopping: {
    message: `El establecimiento tiene desactivada la compra online.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureGetTpvInfo: {
    message: `Error al obtener la información del TPV.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureGetTpvInfoIncomplete: {
    message: `El establecimiento no tiene completa la configuración de compra online.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  successTpvPayment: {
    message: `El pago se ha realizado con éxito.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failedTpvPayment: {
    message: `Se ha producido un error al realizar el pago.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  successUpdateMerchant: {
    message: `Configuración de compra online actualizada.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: SnackbarConfig.durations.success,
    } as MatSnackBarConfig,
  },
  failureInvalidOnlinePurchaseNumberOrSecurityKey: {
    message: `Número de compra online o clave de seguridad inválida. Por favor, actualiza el wallet en tu móvil e intenta nuevamente.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureInsufficienBalance: {
    message: `Tu saldo es insuficiente para aplicar el bono.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureMerchantNotFoundOrInactive: {
    message: `El establecimiento no existe o no está ACTIVO.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureInvalidConfirmationUrl: {
    message: `La configuración (URL de confirmación) de pago online del establecimiento no es válida.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureMerchantHasNotBudget: {
    message: `El establecimiento ha alcanzado el tope de su presupuesto. Por lo tanto no se puede hacer más pagos.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
  failureMerchantCantApplyMoreBonuses: {
    message: `El establecimiento ha alcanzado el tope de su presupuesto. Por lo tanto no se puede hacer más pagos.`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
};
