import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQBonoEnv } from '@qaroni-core/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionHttpService {
  constructor(private http: HttpClient) {}

  public getTransactions$(
    merchantID: number | string,
    params?: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/transactions`;

    return this.http.get(url, { observe: 'response', params });
  }

  public sendReportExcel$(
    merchantID: number | string,
    params?: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/transactions/excel`;

    return this.http.post(url, null, { observe: 'response', params });
  }

  public buildDownloadExcelUrl(
    merchantID: number | string,
    params?: Params
  ): string {
    let url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/transactions/excel`;

    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const element = params[key];
          if (!url.includes('?')) {
            url += '?' + key + '=' + element;
          } else {
            url += '&' + key + '=' + element;
          }
        }
      }
    }
    return url;
  }
}
