import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Transaction,
  TransactionDataSource,
  TransactionService
} from '@qaroni-app/application/transactions';

@Component({
  selector: 'qaroni-transactions-table',
  templateUrl: './transactions-table.component.html',
  styleUrls: ['./transactions-table.component.scss'],
})
export class TransactionsTableComponent implements OnInit, AfterViewInit {
  @Input() showPaginator = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Transaction>;
  public dataSource: TransactionDataSource;

  public displayedColumns: string[] = [
    'creationDate',
    'code',
    'fullAmount',
    'amount',
    'description',
    'type',
    'result',
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transactionService: TransactionService
  ) {}

  ngOnInit(): void {
    this.dataSource = new TransactionDataSource(
      this.route,
      this.router,
      this.transactionService
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
