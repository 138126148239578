import { Component, OnInit } from '@angular/core';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';

@Component({
  selector: 'qaroni-alert-dashboard',
  templateUrl: './alert-dashboard.component.html',
  styleUrls: ['./alert-dashboard.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class AlertDashboardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
