<div class="row">
  <div class="col-12 mb-3">
    <mat-card @enterFadeIn class="bg-grey">
      <mat-card-content class="text-right">
        <div class="row">
          <div class="col-auto">
            <a [href]="AppUrlsConfig.stickerPNG" target="_blank" download>
              <button
                @toggleFade
                mat-raised-button
                color="primary"
                type="button"
                class="my-1"
              >
                <mat-icon>image</mat-icon>
                {{ "Pegatina en imagen PNG" | translate }}
              </button>
            </a>
          </div>
          <div class="col-auto">
            <a [href]="AppUrlsConfig.stickerPDF" target="_blank" download>
              <button
                @toggleFade
                mat-raised-button
                color="primary"
                type="button"
                class="my-1"
              >
                <mat-icon>picture_as_pdf</mat-icon>
                {{ "Pegatina en fichero PDF" | translate }}
              </button>
            </a>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
