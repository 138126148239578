export const InputConfig = {
  user: {
    password: {
      minLength: 8,
    },
  },
  address: {
    alias: {
      maxLength: 40,
    },
    line: {
      maxLength: 80,
    },
    postalCode: {
      maxLength: 5,
      minLength: 5,
    },
    city: {
      maxLength: 60,
    },
    stateProvince: {
      maxLength: 50,
    },
    country: {
      maxLength: 2,
    },
    council: {
      maxLength: 50,
    },
  },
};
