<ng-container *ngIf="merchant">
  <mat-card @toggleFade class="bg-grey h-100">
    <div mat-card-image>
      <qaroni-image-aspect-ratio [src]="merchant?.imageUrl" aspectRatio="3:2"></qaroni-image-aspect-ratio>
    </div>
    <mat-card-content>
      <div class="float-right pl-2 pt-1" *ngIf="merchant?.web">
        <a [href]="merchant?.web" target="_blank" class="qaroni-link" [matTooltip]="'Visitar página web'|translate">
          <mat-icon class="mat-icon-18">web</mat-icon>
        </a>
      </div>
      <div class="mat-h3 mb-0">{{merchant?.tradename}}</div>
      <div class="px-2">
        <qaroni-address-string [address]="merchant"></qaroni-address-string>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
