import { SnackbarConfig } from '@qaroni-core/config';

export const ImagesSnackbars = {
  failFormatSize: {
    message: `Alguna de las imágenes no cumple las condiciones (Formatos: jpg, png. Tamaño máximo 4 Mb.)`,
    action: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    },
  },
};
