import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { Branch, Merchant, MerchantService } from '@qaroni-core/entities';
import { AllAppService } from '@qaroni-core/services';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'qaroni-branches-selector',
  templateUrl: './branches-selector.component.html',
  styleUrls: ['./branches-selector.component.scss'],
})
export class BranchesSelectorComponent implements OnInit {
  private branchIdSkeleton = {
    branchId: [''],
  };

  public branchIdForm: FormGroup = this.fb.group(this.branchIdSkeleton);

  public merchant$: Observable<Merchant> = this.merchantService
    .getMerchant$()
    .pipe(shareReplay(1));

  public branches: Branch[] = [];

  private subs: Subscription = new Subscription();
  private queryParamsHasPage: boolean;

  constructor(
    private route: ActivatedRoute,
    private allApp: AllAppService,
    private merchantService: MerchantService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.subs.add(this.merchant$.subscribe(this.getMerchant));
    this.subs.add(this.route.queryParamMap.subscribe(this.getQueryParamMap));
    this.merchantService.getMerchant();
  }

  get branchId(): AbstractControl {
    return this.branchIdForm.get('branchId');
  }

  private getMerchant = (merchant: Merchant): void => {
    if (merchant && merchant.branches && merchant.branches.length > 0) {
      this.branches = merchant.branches;
    } else {
      this.branches.length = 0;
    }
  }

  private getQueryParamMap = (queryParamMap: ParamMap): void => {
    this.queryParamsHasPage = queryParamMap.has('page');
    if (queryParamMap.has('branchId') && queryParamMap.get('branchId').trim()) {
      const branchId = parseInt(queryParamMap.get('branchId').trim(), 10);
      this.branchId.setValue(branchId);
    } else {
      this.branchId.setValue('');
    }
  }

  public onBranchChange(event: MatSelectChange): void {
    if (event) {
      const queryParams: Params = { ...this.route.snapshot.queryParams };
      if (this.branchId.value) {
        queryParams.branchId = this.branchId.value;
      } else {
        this.branchId.setValue('');
        delete queryParams.branchId;
      }
      if (this.queryParamsHasPage) {
        queryParams.page = 1;
      }
      this.allApp.router.navigate([], {
        relativeTo: this.route,
        queryParams,
      });
    }
  }
}
