import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@qaroni-shared/material';
import { PipesModule } from '@qaroni-shared/pipes';
import { AddressPreviewComponent } from './address-preview/address-preview.component';
import { AddressStringComponent } from './address-string/address-string.component';
import { AlertDashboardComponent } from './alert-dashboard/alert-dashboard.component';
import { AlertSupportComponent } from './alert-support/alert-support.component';
import { AlertUserActiveComponent } from './alert-user-active/alert-user-active.component';
import { BranchPreviewComponent } from './branch-preview/branch-preview.component';
import { BranchesSelectorComponent } from './branches-selector/branches-selector.component';
import { ChangeLanguageComponent } from './change-language/change-language.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { ErrorsHttpDialogComponent } from './dialogs/errors-http-dialog/errors-http-dialog.component';
import { GrecaptchaComponent } from './grecaptcha/grecaptcha.component';
import { HelpSectionComponent } from './help-section/help-section.component';
import { ImageAspectRatioComponent } from './image-aspect-ratio/image-aspect-ratio.component';
import { ImageDefaultComponent } from './image-default/image-default.component';
import { InputErrorMessagesComponent } from './input-error-messages/input-error-messages.component';
import { OutsideFooterComponent } from './layout/outside-footer/outside-footer.component';
import { OutsideInitComponent } from './layout/outside-init/outside-init.component';
import { OutsideToolbarComponent } from './layout/outside-toolbar/outside-toolbar.component';
import { MerchantPreviewComponent } from './merchant-preview/merchant-preview.component';
import { NoticeRaiseBudgetComponent } from './notices/notice-raise-budget/notice-raise-budget.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { RequiredIndicatorComponent } from './required-indicator/required-indicator.component';
import { StickersDownloadsComponent } from './stickers-downloads/stickers-downloads.component';
import { TotalAmountsCardsComponent } from './total-amounts-cards/total-amounts-cards.component';
import { TransactionsTableComponent } from './transactions-table/transactions-table.component';
@NgModule({
  declarations: [
    AddressPreviewComponent,
    AddressStringComponent,
    AlertDashboardComponent,
    AlertSupportComponent,
    AlertUserActiveComponent,
    BranchesSelectorComponent,
    BranchPreviewComponent,
    ChangeLanguageComponent,
    ConfirmationDialogComponent,
    DateRangeComponent,
    ErrorsHttpDialogComponent,
    GrecaptchaComponent,
    HelpSectionComponent,
    ImageAspectRatioComponent,
    ImageDefaultComponent,
    InputErrorMessagesComponent,
    MerchantPreviewComponent,
    NoticeRaiseBudgetComponent,
    OutsideFooterComponent,
    OutsideInitComponent,
    OutsideToolbarComponent,
    PageTitleComponent,
    ProgressSpinnerComponent,
    RequiredIndicatorComponent,
    StickersDownloadsComponent,
    TotalAmountsCardsComponent,
    TransactionsTableComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  exports: [
    AddressPreviewComponent,
    AddressStringComponent,
    AlertDashboardComponent,
    AlertSupportComponent,
    AlertUserActiveComponent,
    BranchesSelectorComponent,
    BranchPreviewComponent,
    ChangeLanguageComponent,
    DateRangeComponent,
    GrecaptchaComponent,
    HelpSectionComponent,
    ImageAspectRatioComponent,
    ImageDefaultComponent,
    InputErrorMessagesComponent,
    MerchantPreviewComponent,
    NoticeRaiseBudgetComponent,
    OutsideFooterComponent,
    OutsideInitComponent,
    OutsideToolbarComponent,
    PageTitleComponent,
    ProgressSpinnerComponent,
    RequiredIndicatorComponent,
    StickersDownloadsComponent,
    TotalAmountsCardsComponent,
    TransactionsTableComponent,
  ],
})
export class ComponentsModule {}
