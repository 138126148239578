<div @enterFadeIn class="alert alert-info" role="alert">
  <h4 class="alert-heading font-weight-bold">
    {{ "Descarga la aplicación móvil desde" | translate }}:
  </h4>
  <div class="row">
    <div class="col-6 text-center">
      <p class="lead text-truncate">Google Play Store</p>
      <a [href]="AppUrlsConfig.ownerAppOnPlayStore" target="_blank">
        <img
          [src]="ImagesConfig.googlePlayStoreIcon"
          [alt]="ImagesConfig.googlePlayStoreIconAlt"
          class="img-fluid play-store-img"
        />
      </a>
    </div>
    <div class="col-6 text-center">
      <p class="lead text-truncate">Apple App Store</p>
      <a [href]="AppUrlsConfig.ownerAppOnAppleStore" target="_blank">
        <img
          [src]="ImagesConfig.appleStoreIcon"
          [alt]="ImagesConfig.appleStoreIconAlt"
          class="img-fluid app-store-img"
        />
      </a>
    </div>
  </div>
</div>
