export const AppUrlsConfig = {
  ownerAppOnPlayStore: `https://play.google.com/store/apps/details?id=com.qaroni.qbonos.navalmoral`,

  ownerAppOnAppleStore: `https://apps.apple.com/es/app/bonos-consumo-navalmoral/id1603933126`,

  exerciseOfLaw: ``,
  bonoCarrusel: ``,

  stickerPNG: ``,
  stickerPDF: ``,
};
