import { Component, OnInit } from '@angular/core';
import { AppUrlsConfig } from '@qaroni-core/config';
import {
  qaroniEnterFadeInTrigger,
  qaroniToggleFadeTrigger
} from '@qaroni-shared/animations';

@Component({
  selector: 'qaroni-stickers-downloads',
  templateUrl: './stickers-downloads.component.html',
  styleUrls: ['./stickers-downloads.component.scss'],
  animations: [qaroniToggleFadeTrigger, qaroniEnterFadeInTrigger],
})
export class StickersDownloadsComponent implements OnInit {
  AppUrlsConfig = AppUrlsConfig;

  constructor() {}

  ngOnInit(): void {}
}
