import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQBonoEnv } from '@qaroni-core/config';
import { Branch } from '@qaroni-core/entities/merchant';
import { Observable } from 'rxjs';
import { Merchant } from '../types/merchant';

@Injectable({
  providedIn: 'root',
})
export class MerchantHttpService {
  constructor(private http: HttpClient) {}

  public getMerchants$(params?: Params): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants`;

    return this.http.get(url, { observe: 'response', params });
  }

  public getMerchant$(
    merchantID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}`;

    return this.http.get(url, { observe: 'response' });
  }

  public updateMerchant$(
    merchantID: number | string,
    merchant: Merchant
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}`;

    return this.http.patch(url, merchant, { observe: 'response' });
  }

  public addImage$(
    merchantID: number | string,
    dataMultipart: FormData
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/images`;

    return this.http.post(url, dataMultipart, { observe: 'response' });
  }

  public deleteImage$(
    merchantID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/images`;

    return this.http.delete(url, { observe: 'response' });
  }

  public createBranch$(
    merchantID: number | string,
    branch: Branch
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/branches`;

    return this.http.post(url, branch, { observe: 'response' });
  }

  public getBranch$(
    merchantID: number | string,
    branchID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/branches/${branchID}`;

    return this.http.get(url, { observe: 'response' });
  }

  public updateBranch$(
    merchantID: number | string,
    branchID: number | string,
    branch: Branch
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/branches/${branchID}`;

    return this.http.patch(url, branch, { observe: 'response' });
  }

  public deleteBranch$(
    merchantID: number | string,
    branchID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/branches/${branchID}`;

    return this.http.delete(url, { observe: 'response' });
  }

  public addBranchImage$(
    merchantID: number | string,
    branchID: number | string,
    dataMultipart: FormData
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/branches/${branchID}/images`;

    return this.http.post(url, dataMultipart, { observe: 'response' });
  }

  public deleteBranchImage$(
    merchantID: number | string,
    branchID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/branches/${branchID}/images`;

    return this.http.delete(url, { observe: 'response' });
  }

  public getMerchantTpvInfo$(
    merchantID: number | string,
    params?: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/tpvs`;

    return this.http.get(url, { observe: 'response', params });
  }
}
