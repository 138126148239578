import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '@qaroni-core/config';

export const TransactionSnackbars = {
  successSendReportExcel: {
    message: `Se envió el reporte a tu correo electrónico`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.success,
      duration: 20000,
    } as MatSnackBarConfig,
  },
};
