<div class="container-fluid px-0">
  <div class="row no-gutters">
    <div class="col-12 bg-footer text-white py-4">
      <div class="container">
        <div
          class="row justify-content-center justify-content-lg-between align-items-center"
        >
          <div class="col-auto text-center">
            <img
              id="logo-owner"
              [src]="ImagesConfig.footerOwner"
              [alt]="ImagesConfig.footerOwnerAlt"
              class="img-fluid m-2"
            />
            <img
              id="logo-partner"
              [src]="ImagesConfig.footerPartner"
              [alt]="ImagesConfig.footerPartnerAlt"
              class="img-fluid m-2"
            />
            <img
              id="logo-camara"
              [src]="ImagesConfig.footerCamaraCaceres"
              [alt]="ImagesConfig.footerCamaraCaceresAlt"
              class="img-fluid m-2"
            />
            <img
              id="logo-qaroni"
              [src]="ImagesConfig.footerQaroni"
              [alt]="ImagesConfig.footerQaroniAlt"
              class="img-fluid m-2"
            />
          </div>
          <div class="w-100 d-block d-lg-none my-2 my-lg-0"></div>
          <div class="col-auto text-center">
            <a
              [routerLink]="['/help-video']"
              class="qaroni-white-link footer-link"
              >{{ "Vídeo tutorial" | translate }}</a
            >
            <span class="mx-1"> | </span>
            <a
              [routerLink]="['/legal']"
              class="qaroni-white-link footer-link"
              >{{ "Aviso legal" | translate }}</a
            >
            <span class="mx-1"> | </span>
            <a
              [routerLink]="['/support']"
              class="qaroni-white-link footer-link"
              >{{ "Soporte" | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
