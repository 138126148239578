import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { MerchantTpvInfo } from '@qaroni-app/application/online-shopping/types/merchant-tpv-info';
import { OnlineShoppingSnackbars } from '@qaroni-app/application/online-shopping/types/online-shopping-snackbars.config';
import { Branch } from '@qaroni-core/entities/merchant';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Merchant } from '../types/merchant';
import { MerchantHttpService } from './merchant-http.service';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  protected readonly merchantsSubject = new Subject<Merchant[]>();
  protected readonly merchantSubject = new Subject<Merchant>();
  protected readonly merchantPublicSubject = new Subject<Merchant>();
  protected readonly merchantTpvInfoSubject = new Subject<MerchantTpvInfo>();

  protected readonly branchSubject = new Subject<Branch>();

  constructor(
    private allApp: AllAppService,
    private merchantHttp: MerchantHttpService,
    private commonsHttp: CommonsHttpService,
    private oAuthStorage: OAuthStorageService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  public hasLatLng(merchant: Merchant): boolean {
    if (merchant?.latitude && merchant?.longitude) {
      return true;
    }
    return false;
  }

  public getLatLng(merchant: Merchant): google.maps.LatLngLiteral {
    if (this.hasLatLng(merchant)) {
      const latLng: google.maps.LatLngLiteral = {
        lat: merchant?.latitude,
        lng: merchant?.longitude,
      };
      return latLng;
    }
    return null;
  }

  // ==========================================================================================
  // Get Merchants
  // ==========================================================================================

  public getMerchants$(): Observable<Merchant[]> {
    return this.merchantsSubject.asObservable();
  }

  public getMerchants(params?: Params): void {
    this.enableLoading();
    this.merchantHttp
      .getMerchants$(params)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({
        next: this.nextGetMerchants,
        error: this.errorGetMerchants,
      });
  }

  private nextGetMerchants = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchants: Merchant[] = data.body.result;
      this.merchantsSubject.next(merchants);
    } else {
      this.merchantsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetMerchants = (error: HttpErrorResponse): void => {
    this.merchantsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Merchant
  // ==========================================================================================

  public getMerchant$(): Observable<Merchant> {
    return this.merchantSubject.asObservable();
  }

  public getMerchant(): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .getMerchant$(this.oAuthStorage.getMerchantID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetMerchant,
          error: this.errorGetMerchant,
        });
    }
  }

  private nextGetMerchant = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetMerchant = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Update Merchant
  // ==========================================================================================

  public updateMerchant(merchant: Merchant): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .updateMerchant$(this.oAuthStorage.getMerchantID, merchant)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextUpdateMerchant,
          error: this.errorUpdateMerchant,
        });
    }
  }

  private nextUpdateMerchant = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorUpdateMerchant = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Add Image
  // ==========================================================================================

  public addImage(dataMultipart: FormData): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .addImage$(this.oAuthStorage.getMerchantID, dataMultipart)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({ next: this.nextAddImage, error: this.errorAddImage });
    }
  }

  private nextAddImage = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorAddImage = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Delete Image
  // ==========================================================================================

  public deleteImage(): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .deleteImage$(this.oAuthStorage.getMerchantID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextDeleteImage,
          error: this.errorDeleteImage,
        });
    }
  }

  private nextDeleteImage = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorDeleteImage = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Add Branch
  // ==========================================================================================

  public createBranch(branch: Branch): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .createBranch$(this.oAuthStorage.getMerchantID, branch)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextCreateBranch,
          error: this.errorCreateBranch,
        });
    }
  }

  private nextCreateBranch = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus201(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorCreateBranch = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Branch
  // ==========================================================================================

  public getBranch$(): Observable<Branch> {
    return this.branchSubject.asObservable();
  }

  public getBranch(branchID: number | string): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .getBranch$(this.oAuthStorage.getMerchantID, branchID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetBranch,
          error: this.errorGetBranch,
        });
    }
  }

  private nextGetBranch = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const branch: Branch = data.body.result[0];
      this.branchSubject.next(branch);
    } else {
      this.branchSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetBranch = (error: HttpErrorResponse): void => {
    this.branchSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Update Branch
  // ==========================================================================================

  public updateBranch(branchID: number | string, branch: Branch): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .updateBranch$(this.oAuthStorage.getMerchantID, branchID, branch)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextUpdateBranch,
          error: this.errorUpdateBranch,
        });
    }
  }

  private nextUpdateBranch = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorUpdateBranch = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Delete Branch
  // ==========================================================================================

  public deleteBranch(branchID: number | string): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .deleteBranch$(this.oAuthStorage.getMerchantID, branchID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextDeleteBranch,
          error: this.errorDeleteBranch,
        });
    }
  }

  private nextDeleteBranch = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorDeleteBranch = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Add Branch Image
  // ==========================================================================================

  public addBranchImage(
    branchID: number | string,
    dataMultipart: FormData
  ): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .addBranchImage$(
          this.oAuthStorage.getMerchantID,
          branchID,
          dataMultipart
        )
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextAddBranchImage,
          error: this.errorAddBranchImage,
        });
    }
  }

  private nextAddBranchImage = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const branch: Branch = data.body.result[0];
      this.branchSubject.next(branch);
    } else {
      this.branchSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorAddBranchImage = (error: HttpErrorResponse): void => {
    this.branchSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Delete Branch Image
  // ==========================================================================================

  public deleteBranchImage(branchID: number | string): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getMerchantID) {
      this.enableLoading();
      this.merchantHttp
        .deleteBranchImage$(this.oAuthStorage.getMerchantID, branchID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextDeleteBranchImage,
          error: this.errorDeleteBranchImage,
        });
    }
  }

  private nextDeleteBranchImage = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const branch: Branch = data.body.result[0];
      this.branchSubject.next(branch);
    } else {
      this.branchSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorDeleteBranchImage = (error: HttpErrorResponse): void => {
    this.branchSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Merchant TPV Info
  // ==========================================================================================

  public getMerchantTpvInfo$(): Observable<MerchantTpvInfo> {
    return this.merchantTpvInfoSubject.asObservable();
  }

  public getMerchantTpvInfo(
    merchantId: number | string,
    params?: Params
  ): void {
    if (merchantId) {
      this.enableLoading();
      this.merchantHttp
        .getMerchantTpvInfo$(merchantId, params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetMerchantTpvInfo,
          error: this.errorGetMerchantTpvInfo,
        });
    }
  }

  private nextGetMerchantTpvInfo = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchantTpvInfoPublic: MerchantTpvInfo = data.body.result[0];
      this.merchantTpvInfoSubject.next(merchantTpvInfoPublic);
    } else {
      this.merchantTpvInfoSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OnlineShoppingSnackbars.failureGetTpvInfo.message
        ),
        OnlineShoppingSnackbars.failureGetTpvInfo.closeBtn,
        OnlineShoppingSnackbars.failureGetTpvInfo.config
      );
    }
  }

  private errorGetMerchantTpvInfo = (error: HttpErrorResponse): void => {
    this.merchantTpvInfoSubject.next(null);
    if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        'The full amount field is required.'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OnlineShoppingSnackbars.failureGetTpvInfoFullAmountIsRequired.message
        ),
        OnlineShoppingSnackbars.failureGetTpvInfoFullAmountIsRequired.closeBtn,
        OnlineShoppingSnackbars.failureGetTpvInfoFullAmountIsRequired.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0004',
        'A required field is missing.',
        'The order field is required.'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OnlineShoppingSnackbars.failureGetTpvInfoOrderIsRequired.message
        ),
        OnlineShoppingSnackbars.failureGetTpvInfoOrderIsRequired.closeBtn,
        OnlineShoppingSnackbars.failureGetTpvInfoOrderIsRequired.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0007',
        'Information not found.',
        'El establecimiento tiene incompleta la configuración de compra online'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OnlineShoppingSnackbars.failureGetTpvInfoIncomplete.message
        ),
        OnlineShoppingSnackbars.failureGetTpvInfoIncomplete.closeBtn,
        OnlineShoppingSnackbars.failureGetTpvInfoIncomplete.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0019',
        'Merchant has not more budget to apply bonus.',
        'El establecimiento ha alcanzado el tope de su presupuesto'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OnlineShoppingSnackbars.failureMerchantHasNotBudget.message
        ),
        OnlineShoppingSnackbars.failureMerchantHasNotBudget.closeBtn,
        OnlineShoppingSnackbars.failureMerchantHasNotBudget.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0023',
        'Merchant was not found or is not ACTIVE.',
        'El establecimiento no existe o no está ACTIVO'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OnlineShoppingSnackbars.failureMerchantNotFoundOrInactive.message
        ),
        OnlineShoppingSnackbars.failureMerchantNotFoundOrInactive.closeBtn,
        OnlineShoppingSnackbars.failureMerchantNotFoundOrInactive.config
      );
    } else if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0024',
        'Online shop disabled for merchant.',
        'El establecimiento tiene la compra online deshabilitada'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OnlineShoppingSnackbars.failureMerchantHasDisabledOnlineShopping
            .message
        ),
        OnlineShoppingSnackbars.failureMerchantHasDisabledOnlineShopping
          .closeBtn,
        OnlineShoppingSnackbars.failureMerchantHasDisabledOnlineShopping.config
      );
    } else {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          OnlineShoppingSnackbars.failureGetTpvInfo.message
        ),
        OnlineShoppingSnackbars.failureGetTpvInfo.closeBtn,
        OnlineShoppingSnackbars.failureGetTpvInfo.config
      );
    }
    this.commonsHttp.errorsHttp.communication(error);
  }
}
