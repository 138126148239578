import { Component, Input, OnInit } from '@angular/core';
import { Branch } from '@qaroni-core/entities';
import { qaroniToggleFadeTrigger } from '@qaroni-shared/animations';

@Component({
  selector: 'qaroni-branch-preview',
  templateUrl: './branch-preview.component.html',
  styleUrls: ['./branch-preview.component.scss'],
  animations: [qaroniToggleFadeTrigger],
})
export class BranchPreviewComponent implements OnInit {
  @Input() branch: Branch;

  constructor() {}

  ngOnInit(): void {}
}
